import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { DataBury } from 'renderbus-data-bury'
import { API, Axios } from '@renderbus/common/utils'
import { Layout, SEO, Wrapper } from '@renderbus/common/components'
import {
  BannerBgWrap,
  BannerSubTitle,
  BannerTitle,
  BannerWrap,
  DownloadButton,
  DownloadButtonWrap,
  DownloadContent,
  DownloadContentImg,
  DownloadFeatures,
  DownloadInfoTitle,
  DownloadInfoTitleSub,
  DownloadInfoWrap,
  DownloadItemWrap,
  DownloadLink,
  DownloadSystemText,
  DownloadTabsWrap,
  DownloadTip,
  DownloadVersion,
  SectionTitleLocal,
  Video,
  VideoSection,
  VideoWrap,
  LinkText,
} from './download.atom'
import BannerBg from '../images/download/banner-bg.png'
import BannerBgMb from '../images/download/banner-bg-mb.png'
import RenderingsPng from '../images/download/renderings-png.png'
import PosterImg from '../images/download/3dsmax.jpg'
import WindowsIcon from '../images/download/windows-icon.png'
import WindowsBlueIcon from '../images/download/windows-blue-icon.png'
import DownloadMbIcon from '../images/download/download-mb-icon.png'
import QRCodeMb from '../images/download/QR-code-mb.png'
import { isTest } from '@renderbus/common/service'
import { useMedia } from 'use-media'
import { DataCollector } from '@rayvision/data-collect'

const XgtDownload = props => {
  const {
    location,
    pageContext: { topThreeShare },
  } = props
  const [renderingInfoArr, setRenderingInfoArr] = useState([])
  const isMobile = useMedia({ maxWidth: 768 })
  const userInfo = useRef(null)

  useEffect(() => {
    const instance = axios.create({
      baseURL: API.auth.downloadHost,
    })
    Promise.all([instance.get(API.auth.vfxNewVersion), instance.get(API.auth.vfxVersion)]).then(
      res => {
        const renderingInfo = res.map(item => item.data)
        setRenderingInfoArr(renderingInfo)
      },
    )
  }, [])

  useEffect(() => {
    const globalInstance = new Axios(isTest() ? API.auth.testHost : API.auth.host)
    globalInstance.post(API.auth.queryUser).then(res => {
      if (res.code === 200) {
        userInfo.current = res.data
      }
    })
  }, [])

  const downloadSoftWare = (link, version) => {
    if (link) {
      let a = document.createElement('a')
      a.href = link
      if (version) {
        a[`data-verison`] = version
      }
      a.click()
    }
  }

  return (
    <Layout location={location} topThreeShare={topThreeShare}>
      <SEO
        title='Renderbus效果图云渲染客户端下载-效果图云渲染工具-3d效果图云渲染器'
        keywords='效果图云渲染客户端，效果图云渲染工具，3d效果图云渲染器，瑞云渲染效果图客户端'
        description='效果图云渲染客户端是一款由Renderbus瑞云渲染为设计效果图用户打造的渲染工具，可实现一键发布作业、实时查看渲染进度、实时预览效果、还包含丰富的素材，现在注册即可免费体验云渲染。'
        sharePostSlug='xgt-download.html'
      />
      <BannerWrap>
        <BannerBgWrap>
          <img src={isMobile ? BannerBgMb : BannerBg} alt='' />
        </BannerBgWrap>
        <BannerTitle>下载效果图云渲染客户端</BannerTitle>
        <BannerSubTitle>
          支持免费渲小图 · AI加速降本增效 · 实时预览结果 · 一键批量提交渲染
        </BannerSubTitle>
        <DownloadTabsWrap>
          <DownloadContent>
            <DownloadContentImg>
              <img src={RenderingsPng} alt='下载效果图云渲染客户端' />
            </DownloadContentImg>
            {renderingInfoArr.length > 0 && (
              <DownloadInfoWrap>
                <DownloadInfoTitle>Renderbus云渲染客户端</DownloadInfoTitle>
                <DownloadInfoTitleSub>
                  开发者：深圳市瑞云科技股份有限公司｜
                  <a
                    href='https://www.renderbus.com/support-new/privacy-policy/'
                    className='is-picture'
                  >
                    隐私协议
                  </a>
                </DownloadInfoTitleSub>
                <DownloadButtonWrap>
                  <DownloadItemWrap>
                    {!isMobile ? (
                      <DownloadButton
                        isBlue
                        onClick={() => {
                          downloadSoftWare(
                            renderingInfoArr[0].download_url,
                            renderingInfoArr[0].version,
                          )
                          DataBury.getCustomEventInfo({
                            event: 'clientEvent',
                            extraData: {
                              zone: 1, // 1 境内   2境外
                              terminal: 1, // 1 官网  2渲染前台
                              type: 1, // 1:下载  2:安装
                              channel: 1, // 1:效果图客户端 2:renderbus动画客户端
                            },
                            baseURL: isTest() ? API.auth.testHost : API.auth.host,
                          })
                          DataCollector.emit('download_client', {
                            immediate_to_huoshan: 1,
                            url: location.href,
                            user_has_recharge: userInfo?.current?.isFirstRecharge,
                            user_business_type: 0,
                          })
                        }}
                        width='320px'
                        imgWidth='24px'
                        imgHeight='24px'
                      >
                        <img src={WindowsIcon} alt='' />
                        下载新版Windows客户端
                      </DownloadButton>
                    ) : (
                      <DownloadSystemText isBlue imgWidth={30} imgHeight={30}>
                        <img src={WindowsBlueIcon} alt='' />
                        <span>Windows客户端</span>
                      </DownloadSystemText>
                    )}
                    <DownloadVersion>{`${renderingInfoArr[0].update_time} | 版本号${
                      renderingInfoArr[0].version
                    } | 支持${renderingInfoArr[0].update_content.version
                      .split('支持')[1]
                      .trim()}`}</DownloadVersion>
                    <DownloadFeatures>
                      <div>功能介绍：</div>
                      {Object.values(renderingInfoArr[0].update_content.log).map(item => (
                        <div key={item}>{item}</div>
                      ))}
                    </DownloadFeatures>
                  </DownloadItemWrap>
                </DownloadButtonWrap>
                {!isMobile && (
                  <DownloadLink
                    className='renderings'
                    href={renderingInfoArr[1].download_url}
                    target='_blank'
                    onClick={() =>
                      DataBury.getCustomEventInfo({
                        event: 'clientEvent',
                        extraData: {
                          zone: 1, // 1 境内   2境外
                          terminal: 1, // 1 官网  2渲染前台
                          type: 1, // 1:下载  2:安装
                          channel: 1, // 1:效果图客户端 2:renderbus动画客户端
                        },
                        baseURL: isTest() ? API.auth.testHost : API.auth.host,
                      })
                    }
                  >
                    下载旧版Windows客户端
                  </DownloadLink>
                )}
                {isMobile && (
                  <>
                    <DownloadTip>
                      <img src={DownloadMbIcon} alt='' />
                      请到PC端下载客户端
                    </DownloadTip>
                    <DownloadContentImg mbWidth={400} mbHeight={400}>
                      <img src={QRCodeMb} alt='' />
                    </DownloadContentImg>
                    <DownloadInfoWrap>
                      <DownloadInfoTitle>微信扫一扫</DownloadInfoTitle>
                      <DownloadInfoTitleSub mbFontSize={30}>
                        随时随地监控作业进度{' '}
                      </DownloadInfoTitleSub>
                    </DownloadInfoWrap>
                  </>
                )}
              </DownloadInfoWrap>
            )}
          </DownloadContent>
        </DownloadTabsWrap>
      </BannerWrap>
      <VideoWrap>
        <VideoSection>
          <LinkText>
            效果图用户专用云渲染客户端，影视动画用户请点击
            <a
              className='to-animation'
              href={
                isTest()
                  ? 'http://pre.renderbus.com:8081/download.html'
                  : 'https://www.renderbus.com/download.html'
              }
            >
              下载影视动画云渲染客户端
            </a>
          </LinkText>
          <Wrapper id='pictureVideo' name='pictureVideo'>
            <SectionTitleLocal top='40px'>云渲染客户端视频教程</SectionTitleLocal>
            <Video key='pictureVideo' preload='auto' poster={PosterImg} controls>
              <source
                src='https://videoread.oss-cn-beijing.aliyuncs.com/rending.mp4'
                type='video/mp4'
              ></source>
            </Video>
          </Wrapper>
          <DownloadLink href='https://www.renderbus.com/support-new/single-frame-client-video-tutorial/'>
            点击查看更多软件使用教程
          </DownloadLink>
        </VideoSection>
      </VideoWrap>
    </Layout>
  )
}

export default XgtDownload
